.all {
    display: block;
}

.main {
    padding: 10px 150px;
}

.topBar {
    display: flex;
}

.logoContainer {
    width: fit-content;
    display: inline-block;
}

.chatContainer {
    width: 500px;
    margin: 50px 50px;
    display: inline-block;
    position: relative;
}

.body {
    vertical-align: bottom;
    float: left;
}

.image {
    width: 120px;
    margin: 20px 0 15px 40px;
}

.chatImage {
    margin: 10px;
    width: 400px;
    position: absolute;
    top: 0;
    left: 0;
}

.personImage {
    margin: 10px;
    width: 700px;
    position: absolute;
    left: 20%;
    z-index: -10;
}

.filler {
    width: 90%;
    height: 20px;
    display: inline-block;
}

.buttonText {
    color: #000000;
    padding: 10px 10px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 30px 10px;
}

.getStartedButton {
    background: #F96553;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 10px 30px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 30px 10px;
}

.mainContainer {
    text-align: left;
    width: 50%;
    margin: 140px 20px;
    display: inline-block;
    float:left;
}

.paragraphContainer {
    width: 80%;
    text-align: left;
}

.titleText {
    font-size:60px;
    font-weight: bolder;
}

.paragraphText {
    font-size: 20px;
    font-weight: bolder;
    line-height: 1.3;
    margin: 30px 0;
    color: #696969;
}

.getStartedButton2 {
    background: #F96553;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 10px 30px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: fit-content;
}

.getStartedButton:hover {
    background: #fc7b6b;
}

.getStartedButton2:hover {
    background: #fc7b6b;
}

a {
    text-decoration: none;
}

.blackStrip {
    height: fit-content;
    background-color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 0;
}

.breakRules {
    clear: both;
}

.blackStripCol1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
    margin: 50px 25px 50px 150px;
}

.blackStripCol1Title {
    color: white;
    font-size: 40px;
    font-weight: bolder;
}

.blackStripCol1Paragraph {
    color: white;
    margin-top: 20px;
    font-size: 20px;
}

.blackStripCol2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
    height: fit-content;
    padding-top: 60px;
    margin: 50px 150px 50px 25px;
}

.blackStripCol2row{
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}


.blackStripCol2rowCol1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
    margin-left: 100px
}

.blackStripCol2rowCol2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
}


.blackStripCol2rowCol3 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
}

.blackStripCol2rowCol1Title {
    font-size: 70px;
    font-weight: bolder;
}

.blackStripCol2rowCol1Paragraph {
    font-size: 16px;
    font-weight: bolder;
    color:white;
}



@media screen and (max-width: 1600px) {

    .main {
        padding: 10px 80px;
    }

    .titleText {
        font-size:50px;
    }

    .paragraphText {
        font-size:18px;
        line-height: 1.3;
    }

    .chatImage {
        width: 350px;
    }

    .personImage {
        width: 600px;
        left: 20%;
    }
}

@media screen and (max-width: 1500px) {
    .mainContainer {
        width: 45%;
        margin: 140px 20px;
        display: inline-block;
        float:left;
    }
    .main {
        padding: 10px 80px;
    }

    .titleText {
        font-size:40px;
    }

    .paragraphText {
        font-size:18px;
        line-height: 1.3;
    }

    .chatImage {
        width: 350px;
    }

    .personImage {
        width: 500px;
        left: 25%;
    }
}


@media screen and (max-width: 1350px) {
    .mainContainer {
        width: 40%;
    }

    .main {
        padding: 10px 80px;
    }

    .titleText {
        font-size:30px;
    }

    .paragraphText {
        font-size:16px;
        line-height: 1.3;
    }

    .chatImage {
        width: 350px;
    }

    .personImage {
        width: 500px;
        left: 25%;
    }

    .blackStripCol2row {
        margin: 50px 100px 50px 15px;
    }

    .blackStripCol2rowCol1 {
        margin-left: 10px
    }


}


@media screen and (max-width: 1250px) {

    .mainContainer {
        width: fit-content;
        margin: 80px 20px 50px 20px;
        display: block;
        text-align: center;
        float:none;
    }

    .chatContainer {
        width: 700px;
        display: block;
        margin: 0 auto;
        position: static ;
    }

    .chatImage {
        position: static;
        display: inline-block;
    }

    .personImage {
        left: 60%;
        margin-left: 150px;
        width: 700px;
        position: absolute;
        z-index: -10;
    }

    .breakRules {
        display: block;
        clear: both;
    }

    .paragraphContainer {
        width: 80%;
        text-align: center;
        margin: 0 auto;
    }

    .main {
        padding: 10px 80px;
    }

    .titleText {
        font-size:50px;
    }

    .paragraphText {
        font-size:18px;
        text-align: center;
        line-height: 1.3;
    }

    .chatImage {
        width: 350px;
    }

    .personImage {
        width: 500px;
        left: 25%;
    }

    .getStartedButton2 {
        font-size: 16px;
        margin: 20px auto;
    }

}

@media screen and (max-width: 950px) {
    .paragraphContainer {
        width: 100%;
    }

    .mainContainer {
        width: 100%;
    }
}

@media screen and (max-width: 850px) {

    .mainContainer {
        margin: 20px 0;
        width: 100%;
        text-align: left;
    }

    .image {
        margin: 20px 0 0 0;
    }
    
    .getStartedButton {
        display: none;
    }

    .getStartedButton2 {
        margin: 20px 0;
    }

    .paragraphContainer {
        text-align: left;
    }

    .paragraphText {
        text-align: left;
    }

    .main {
        padding: 10px 20px;
    }

    .titleText {
        font-size: 30px;
    }

    .body {
        width: 100%;
    }

    .personImage {
        display: none;
    }

    .chatContainer {
        text-align: center;
        width: 100%;
        position: relative;
    }

    .chatImage {
        width: 300px;
    }

    .buttonText {
        display: none
    }

    .blackStripCol2rowCol1 {
        margin-left: 0;
        margin-bottom: 20px
    }

    .blackStripCol2rowCol2 {
        margin-bottom: 20px
    }
    .blackStripCol2rowCol3 {
        margin-bottom: 20px
    }

    .image {
        width: 100px
    }

}


@media screen and (max-width: 820px) {
    .image {
        width: 100px
    }

    .blackStripCol1 {
        margin: 50px
    }

    .blackStripCol2 {
        margin: 50px 10px
    }
}

@media screen and (max-width: 660px) {

    .blackStripCol2 {
        margin: 0;
        margin-top: 40px;
        padding: 0
    }

    .blackStripCol2row {
        margin: 0;
        padding: 0
    }
}

@media screen and (max-width: 660px) {

    .blackStripCol1 {
        margin: 50px 50px 0 50px;
        padding: 0
    }

    .blackStripCol2 {
        margin:  20px 50px 0 50px;
        padding: 0
    }
}