.container {
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 50px;
}

.col1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
    margin: 50px 0px 20px 200px;
}

.col2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
    height: fit-content;
    padding-top: 60px;
    margin: 50px 150px 20px 0px;
}

.imageContainer {

}

.image {
    width: 300px;
    padding: 0 0 0 50px
}

.title {
    font-size: 40px;
    font-weight: bolder;
    margin-bottom: 20px;
}

.description {
    font-size: 20px;
    font-weight: bolder;
    line-height: 1.3;
    color: #696969;
}