

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}