.container {
    width: 100%;
    text-align: center;
    padding: 20% 0;
}

.textNotFound {
    margin: 0 auto;
    font-size: 30px;
    color: #212121;
    font-weight: bolder;
}

.buttonBack {
    background: #F96553;
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    opacity: 1;
    padding: 10px 50px;
    width: fit-content;
    user-select: none;
}

.buttonSpacing {
    margin: 20px 0;
}